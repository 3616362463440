// Object.assign polyfill for IE
if (typeof Object.assign != 'function') {
  Object.assign = function (target) {
    'use strict'
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object')
    }

    target = Object(target)
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index]
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key]
          }
        }
      }
    }
    return target
  }
}

import FilterBar from './activitylog.filterbar.jsx'
import Body from './activitylog.body.jsx'
import placeholder from './jquery.placeholder.min.js'

const { users, categoryMap } = INITDATA.getInitialData()

const Main = React.createClass({
  getInitialState() {
    return {
      fromDate: '',
      toDate: '',
      selectedUser: '0',
      selectedCategory: 'All Categories',
      offset: 0,
      activities: [''],
    }
  },

  getFilterParams() {
    return {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      selectedUserKey: users[this.state.selectedUser][0],
      selectedCategory: categoryMap[this.state.selectedCategory],
    }
  },

  componentWillMount() {
    const filterParams = this.getFilterParams()

    const urlParams = new URLSearchParams(window.location.search)
    const selectedCategory = urlParams.get('selectedCategory')
    if (selectedCategory) {
      filterParams.selectedCategory = selectedCategory
    }

    ActivityLogService.getLogData(filterParams).then(response => {
      this.setState({
        activities: response.data.activities,
      })
    })
  },

  updateHandler(filterParams) {
    const modifiedParams = Object.assign({}, filterParams, {
      selectedUserKey: users[filterParams.selectedUser][0],
      selectedCategory: categoryMap[filterParams.selectedCategory],
      offset: 0,
    })

    ActivityLogService.getLogData(modifiedParams).then(response => {
      this.setState({
        fromDate: filterParams.fromDate,
        toDate: filterParams.toDate,
        selectedUser: filterParams.selectedUser,
        selectedCategory: filterParams.selectedCategory,
        activities: response.data.activities,
        offset: modifiedParams.offset,
      })
    })
  },

  handlePageClick(direction) {
    const filterParams = this.getFilterParams()
    if (direction === 'next') {
      filterParams.offset = this.state.offset + 1
    } else {
      filterParams.offset = this.state.offset - 1
    }
    ActivityLogService.getLogData(filterParams).then(response => {
      this.setState({
        activities: response.data.activities,
        offset: filterParams.offset,
      })
    })
  },

  clearFilterHandler() {
    const defaultParams = {
      fromDate: '',
      toDate: '',
      selectedUserKey: 'ALL USERS',
      selectedCategory: categoryMap['All Categories'],
      offset: 0,
    }

    ActivityLogService.getLogData(defaultParams).then(response => {
      this.refs._filterBar.reset()
      this.setState({
        fromDate: '',
        toDate: '',
        selectedUser: '0',
        selectedCategory: 'All Categories',
        activities: response.data.activities,
        offset: 0,
      })
    })
  },

  render() {
    return (
      <div>
        <FilterBar
          data={{
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            selectedUser: this.state.selectedUser,
            selectedCategory: this.state.selectedCategory,
            updateHandler: this.updateHandler,
          }}
          ref={'_filterBar'}
        />
        <Body
          data={{
            activities: this.state.activities,
            offset: this.state.offset,
            handlePageClick: this.handlePageClick,
            clearFilterHandler: this.clearFilterHandler,
          }}
        />
      </div>
    )
  },
})

React.render(<Main />, document.getElementById('activity-log-app'))
